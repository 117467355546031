.wrapper {
  max-width: 120rem;
  width: 100%;
  padding: 0 1.5rem;
  margin: 0 auto; }

.button {
  display: inline-block;
  cursor: pointer; }
  .button--text {
    font-size: 1.4rem;
    text-decoration: underline;
    cursor: pointer;
    color: #9F261D; }
  .button--primary {
    font-size: 1.4rem;
    color: #FFF;
    background: #9F261D;
    border: 0;
    padding: 2rem 3.2rem;
    text-transform: uppercase; }

.text {
  color: #777777; }
  .text--big {
    font-size: 3.6rem;
    line-height: 4.4rem;
    font-family: 'Montserrat', sans-serif; }
    @media only screen and (max-width: 991px) {
      .text--big {
        font-size: 3rem;
        line-height: 3.8rem; } }
  .text--small {
    font-size: 1.4rem;
    line-height: 2.6rem;
    font-weight: 300; }
  .text--normal {
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: 300; }
  .text--bold {
    font-weight: 700; }

.cwhite {
  color: #FFFFFF; }

.cblack {
  color: #000; }

.cred {
  color: #9F261D; }

.heading--big {
  font-size: 3.6rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: #000; }
  .heading--big span {
    color: #9F261D; }
  @media only screen and (max-width: 767px) {
    .heading--big {
      text-align: center; } }

.header__logo {
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .header__logo img {
    max-width: 27rem;
    width: 100%; }
  @media only screen and (max-width: 767px) {
    .header__logo {
      height: 10rem; } }

.intro {
  padding: 10.8rem 0 7.7rem 0;
  position: relative;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD; }
  @media only screen and (max-width: 991px) {
    .intro {
      padding: 7.5rem 0 7.7rem 0; } }
  @media only screen and (max-width: 767px) {
    .intro {
      padding: 0 0 5rem 0; }
      .intro .wrapper {
        padding: 0; } }
  .intro::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 2px);
    width: 50%;
    background-color: #9F261D;
    z-index: 1;
    margin-top: -1px; }
    @media only screen and (max-width: 767px) {
      .intro::before {
        display: none; } }
  .intro__container {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .intro__container {
        flex-direction: column; } }
  .intro__box {
    width: 50%;
    position: relative;
    z-index: 2; }
    .intro__box--left {
      padding-right: 5.7rem; }
      @media only screen and (max-width: 991px) {
        .intro__box--left {
          padding-right: 3rem; } }
      @media only screen and (max-width: 767px) {
        .intro__box--left {
          background-color: #9F261D; } }
    .intro__box--right {
      padding-left: 7rem; }
      @media only screen and (max-width: 991px) {
        .intro__box--right {
          padding-left: 3rem; } }
    @media only screen and (max-width: 767px) {
      .intro__box {
        padding: 3rem 1.5rem;
        width: 100%; } }
  .intro__logo {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -13rem;
    width: 22.8rem;
    height: 22.8rem;
    border-radius: 50%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 20px 44px 0 rgba(32, 51, 58, 0.05);
    z-index: 2; }
    @media only screen and (max-width: 991px) {
      .intro__logo {
        width: 15rem;
        height: 15rem;
        bottom: -8rem; } }
    @media only screen and (max-width: 767px) {
      .intro__logo {
        bottom: -8rem; } }
    .intro__logo img {
      max-width: 11.5rem;
      margin-left: 19px;
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .intro__logo img {
          max-width: 7rem; } }

.why {
  margin-top: 4rem;
  padding-top: 23rem;
  overflow-x: hidden;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .why {
      margin-top: 2rem;
      padding-top: 8rem; } }
  .why .heading {
    margin-bottom: 4.8rem; }
  .why__container {
    display: flex;
    margin: 0 -3.6rem; }
    @media only screen and (max-width: 991px) {
      .why__container {
        flex-wrap: wrap;
        justify-content: center; } }
  .why__item {
    padding: 0 3.6rem;
    flex: 1; }
    @media only screen and (max-width: 991px) {
      .why__item {
        flex: unset;
        width: 50%;
        margin-bottom: 3rem; } }
    @media only screen and (max-width: 767px) {
      .why__item {
        width: 100%; } }
  @media only screen and (max-width: 991px) {
    .why__desc {
      text-align: center; } }
  .why__icon {
    background: #FDF7F6;
    height: 6rem;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.4rem; }
    @media only screen and (max-width: 991px) {
      .why__icon {
        margin: 0 auto 1.4rem auto; } }
  .why__pattern {
    position: absolute;
    top: 0;
    right: 10rem; }

.check {
  margin-top: 15rem;
  position: relative;
  overflow-x: hidden; }
  @media only screen and (max-width: 991px) {
    .check {
      margin-top: 5rem; } }
  .check__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .check__container {
        flex-direction: column; } }
  .check__info {
    max-width: 29rem;
    margin-right: 3rem; }
    @media only screen and (max-width: 767px) {
      .check__info {
        margin-right: 0;
        text-align: center; } }
    .check__info .heading {
      margin-bottom: 2.3rem; }
  .check__video {
    max-width: 77rem;
    width: 100%;
    box-shadow: 0 20px 44px 0 rgba(32, 51, 58, 0.07); }
    .check__video img, .check__video iframe, .check__video video {
      width: 100%;
      min-height: 39.5rem;
      border: 0; }
    @media only screen and (max-width: 767px) {
      .check__video {
        margin-top: 3rem; } }
  .check__pattern {
    position: absolute;
    top: 0;
    right: 0; }
    @media only screen and (max-width: 767px) {
      .check__pattern {
        display: none; } }

.data {
  padding-top: 18rem;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .data {
      padding-top: 10rem; } }
  @media only screen and (max-width: 767px) {
    .data {
      padding-top: 5rem; } }
  .data .heading {
    margin-bottom: 1.3rem; }
  .data .button {
    margin-top: 1.6rem; }
  .data__container {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .data__container {
        flex-direction: column; } }
  .data__item {
    display: flex;
    flex: 1; }
    .data__item:first-child {
      flex: 1.228;
      margin-right: 11%; }
      @media only screen and (max-width: 991px) {
        .data__item:first-child {
          margin-right: 2rem; } }
      @media only screen and (max-width: 767px) {
        .data__item:first-child {
          margin-right: 0;
          margin-bottom: 2rem; } }
  .data__icon {
    height: 10rem;
    width: 10rem;
    min-width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3.2rem;
    background: #FDF7F6; }
    @media only screen and (max-width: 991px) {
      .data__icon {
        margin-right: 1.5rem;
        height: 8rem;
        width: 8rem;
        min-width: 8rem; } }
  .data__pattern {
    position: absolute;
    z-index: -1;
    left: 4rem;
    top: -4rem; }

.faq__list {
  margin-top: 5rem; }
  @media screen and (max-width: 767px) {
    .faq__list {
      margin-top: 2.5rem; } }
  .faq__list li {
    border-bottom: 1px solid #EEEEEE;
    padding: 1.6rem; }

.faq__question {
  cursor: pointer;
  position: relative;
  padding-right: 20px;
  transition: all .3s; }
  .faq__question[aria-expanded="true"] {
    color: #9F261D; }
    .faq__question[aria-expanded="true"] .faq__question-arrow {
      transform: rotate(180deg); }
      .faq__question[aria-expanded="true"] .faq__question-arrow svg g {
        stroke: #000; }

.faq__question-arrow {
  position: absolute;
  right: 0;
  top: 0;
  transition: transform .3s; }

.faq__answer .text {
  padding-top: 1.6rem; }

.contact {
  margin-top: 13rem;
  margin-bottom: 17rem; }
  @media only screen and (max-width: 991px) {
    .contact {
      margin-top: 10rem;
      margin-bottom: 10rem; } }
  @media only screen and (max-width: 767px) {
    .contact {
      margin-top: 4rem;
      margin-bottom: 4rem; } }
  .contact__container {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .contact__container {
        flex-direction: column; } }
  .contact__info {
    padding-top: 7rem;
    border-right: 1px solid #DDDDDD;
    padding-right: 6rem;
    margin-right: 6rem; }
    @media only screen and (max-width: 991px) {
      .contact__info {
        padding-right: 2.5rem;
        margin-right: 2.5rem; } }
    @media only screen and (max-width: 767px) {
      .contact__info {
        padding-right: 0;
        padding-top: 3rem;
        margin-right: 0;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #DDDDDD;
        border-right: 0; } }
  .contact__info-box:not(:last-child) {
    margin-bottom: 3.2rem; }
  .contact__form {
    flex: 1; }
    .contact__form form {
      margin-top: 4.8rem; }
    .contact__form input {
      border: 1px solid #DDDDDD;
      color: #777777;
      letter-spacing: 0.2px;
      line-height: 26px;
      height: 6rem;
      outline: none;
      padding: 5px 15px; }
      .contact__form input.error {
        border: 1px solid #9F261D; }
    .contact__form textarea {
      border: 1px solid #DDDDDD;
      color: #777777;
      letter-spacing: 0.2px;
      line-height: 26px;
      height: 20rem;
      resize: none;
      outline: none;
      padding: 15px; }
      .contact__form textarea.error {
        border: 1px solid #9F261D; }

.form__row {
  display: flex;
  margin: 0 -1.5rem 2.8rem -1.5rem; }
  @media only screen and (max-width: 767px) {
    .form__row {
      flex-direction: column; } }

.form__item {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  width: 50%; }
  @media only screen and (max-width: 767px) {
    .form__item {
      width: 100%; } }
  .form__item label {
    font-size: 1.4rem;
    color: #777777;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 1rem; }
  .form__item--wide {
    width: 100%; }

#maps-contact {
  height: 600px; }

label.error {
  display: none !important; }

.popup__wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s; }

.popup__wrapper.js-active {
  opacity: 1;
  visibility: visible; }

.popup__wrapper.js-active .popup__box {
  opacity: 1;
  visibility: visible; }

.popup__box {
  max-width: 830px;
  height: 70vh;
  width: 100%;
  margin: 0 15px;
  padding: 4rem 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #FFFFFF;
  position: relative;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  overflow-y: auto; }

@media screen and (max-width: 767px) {
  .popup__box {
    padding: 2rem 1.5rem; } }

.popup__box iframe {
  width: 100%;
  height: 100%; }

.popup__title {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #9F261D;
  letter-spacing: 0.8px;
  line-height: 1; }

@media screen and (max-width: 767px) {
  .popup__title {
    font-size: 24px; } }

.popup__content {
  margin: 0 0 35px;
  font-size: 18px;
  line-height: 1.833em;
  color: #fff;
  font-weight: 300;
  letter-spacing: .75px;
  text-align: center; }

.popup__close {
  position: absolute;
  cursor: pointer;
  right: 5rem;
  top: 4rem;
  z-index: 1; }
  @media screen and (max-width: 767px) {
    .popup__close {
      right: 1.5rem;
      top: 2rem; } }

.popup__close svg {
  width: 20px;
  height: 20px; }

.footer {
  background: #000; }
  .footer__container {
    display: flex;
    min-height: 8rem;
    align-items: center;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .footer__container {
        flex-direction: column;
        padding: 1rem 0;
        order: 2; } }
  .footer__info {
    min-height: 4rem;
    display: flex;
    align-items: center; }
    .footer__info p {
      font-weight: 300; }
    .footer__info:first-child {
      padding-right: 6rem;
      border-right: 1px solid rgba(255, 255, 255, 0.2); }
      @media only screen and (max-width: 767px) {
        .footer__info:first-child {
          padding-right: 0;
          border: 0; } }
    .footer__info:last-child {
      padding-left: 6rem; }
      @media only screen and (max-width: 767px) {
        .footer__info:last-child {
          padding-left: 0; } }

.scroll {
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  @media only screen and (max-width: 767px) {
    .scroll {
      order: 1; } }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  line-height: normal; }

body {
  font-size: 1.6rem;
  font-family: 'Ubuntu', sans-serif; }

p {
  margin: 0; }

h1, h2, h3, h4, h5 {
  margin: 0; }

main {
  overflow: hidden; }
